import React, {useContext, useState} from 'react'
import Web3Modal from 'web3modal';
import { AuthContext } from '../contexts/AuthContext';
import {ethers} from 'ethers'

export default function Header({connectWallet}) {
    const [showAbout, setShowAbout] = useState(false);
    const {web3User, setWeb3User, sideMenu, setSideMenu} = useContext(AuthContext);

    const [provider, setProvider] = useState(null);
    const [walletAddress, setWalletAddress] = useState(null);
  

    const disconnectWallet = () => {
      setWeb3User(null);
      setProvider(null);
      setWalletAddress(null);
    };


    const handleWalletClick = () => {
      setSideMenu(false);
      if(web3User) {
        disconnectWallet();
      } else {
        connectWallet();
      }
    }
    
    return (
      <div>
        {sideMenu && (
          <div class="side-menu fixed top-0 right-0 h-screen w-50 text-white">
            <div class="flex flex-col content-center gap-6 mt-10">
              <button className="btn-main btn-side-menu py-2 px-3 mt-4 cursor-pointer rounded-full" onClick={handleWalletClick}>
                  {web3User ? `${web3User.slice(0, 6)}...${web3User.slice(38, 42)}` : "Connect Wallet"}
              </button>
              <a class="pt-2 cursor-pointer" target="_blank" href="https://etherscan.io/address/0xc2fce7d84b5664b2ba9bfd0614a2457b19227e1b">Etherscan</a>
              <a class="pt-2 cursor-pointer" onClick={() => {setShowAbout(true); setSideMenu(false); document.body.style.overflow = "hidden"}}>About</a>  
              <a class="pt-2 cursor-pointer" onClick={() => {setSideMenu(false);}}>Close</a>   
            </div>
          </div>
        )}
        {showAbout && 
          (<div class="w-screen h-screen absolute top-0 left-0 flex flex-column justify-center items-center about-overlay">
            <div class="w-50 about-content h-50 flex flex-col">
              <h2 class="about-title">About:</h2>
              <p class="about-paragraph">Each of these pieces is inspired, or completely pulled, from real life. Sometimes a scratchpad, sometimes a urinal,
                 each selection is intended to communicate a feeling to the reader. When going to an emotional extreme, often a painful process, you don’t forget how you
                  got there. And when it’s someone else who takes you there, you remember you aren’t alone.</p>
              <p class="about-paragraph">
                Once minted, the tweet is permanently deleted.
              </p>
              <h2 class="about-title">Distribution:</h2>
              <p class="about-paragraph">Tweets are available for 0.5 ETH to the public</p>
              <p class="about-paragraph">There will never be new tweets minted as part of this collection, nor any new collections.</p>
              <p class="about-paragraph">The owners of each NFT will be sent a hardcover book with all 250 pieces and a foreword.</p>
              <div class="mt-5">
                <div class="btn-main about-btn py-2 px-10 cursor-pointer rounded-full" onClick={() => {setShowAbout(false); document.body.style.overflow = "scroll"}}>close</div>
                </div>
              </div>
          </div>)}  
        
        <div className="w-full header text-white px-6 bg-transparent items-center bg-black justify-between flex fixed items-center">
            <h1 className="text-2xl font-bold">
              <span className="text-blue-500">GOODGRIEFS</span>.MARKET
            </h1>   
            <div class="flex flex-row gap-6 items-center header-right">
              <a class="pt-2 cursor-pointer" target="_blank" href="https://etherscan.io/address/0xc2fce7d84b5664b2ba9bfd0614a2457b19227e1b">Etherscan</a>
              <a class="pt-2 cursor-pointer" onClick={() => {setShowAbout(true); document.body.style.overflow = "hidden"}}>About</a>  
              <button className="btn-main py-2 px-3 mt-4 cursor-pointer rounded-full" onClick={web3User ? disconnectWallet : connectWallet}>
                  {web3User ? `${web3User.slice(0, 6)}...${web3User.slice(38, 42)}` : "Connect Wallet"}
              </button>
            </div>
        </div>
      </div>
    )
  }