import React, {useEffect, useContext, useState} from 'react'
import TweetCard from './TweetCard';
import verified from '../verified.png';
import { AuthContext } from '../contexts/AuthContext';
import { getDatabase } from 'firebase/database';
import { onValue, ref } from "firebase/database";
import { app } from '../index';

export default function Feed() {

    const arr = Array.from(Array(4).keys());
    const [filteredData, setFilteredData] = useState([]);
    const [availableActive, setAvailableActive] = useState(true);
    const [availableCount, setAvailableCount] = useState(0);
    const {setWeb3User, web3User, contract, sideMenu, setSideMenu} = useContext(AuthContext);
    const [NFTdata, setNFTData] = useState([]);   
    const [loadError, setLoadError] = useState('');
    const [loading, setLoading] = useState(true);
    

    const exampleTweet = {
      body: "you’ve been dating for six months and it feels like love. she lets you stay over while she visits her family. you sell her MacBook Air first. then the TV, the sofa, and the weird old candles her parents gave her. you spend 4 days gambling and 20 minutes on the apology text.",
      date: "2020-01-06T01:37:37.000Z",
      id: "2",
      url: "https://twitter.com/OhYouBlockhead/status/1213998019885973504"
    }

    useEffect(() => {
        const database = getDatabase(app);
        const collectionRef = ref(database, "nft-data");
         return onValue(collectionRef, (snapshot) => {
            const dataItem = snapshot.val();
    
            // Check if dataItem exists
            if (dataItem) {
              // Convert the object values into an array
              const displayItem = Object.values(dataItem);
              setNFTData(displayItem);
              setTimeout(() => {
                setAvailableCount(displayItem.filter((tweet) => {
                  return !tweet.owner;
                }).length);
                filterData(true, displayItem);
                setLoading(false);
              })
            }
          }, error => {
            setLoadError('Error fetching data, try reloading the page.');
          })
       
    },[])

    const filterData = (available, data = NFTdata) => {
      setAvailableActive(available);
      window.scrollTo(0,0);
      setFilteredData(data.filter((tweet) => {
        return available ? !tweet.owner : tweet.owner;
      }));
    }

    return (
      <div className="pt-8">
        {(loading || loadError) && <div class="loading-overlay w-screen h-screen absolute top-0">
          {loading && <div class="feed-spinner"></div>}
          {loadError && <div class="load-error-message">{loadError}</div>}
        </div>}
        <p>feed</p>
        <div className="switch">
            <div class="text-white flex flex-col justify-center px-5 switch-content h-20 border-l border-r">
              <div class="flex flex-row justify-between content-center">
                <div>
                  <div class="flex flex-row items-center gap-1">
                    <a href="https://x.com/OhYouBlockhead" class="main-title link-underline pointer text-xl font-bold" target="_blank">Blockhead</a><img className="mx-1 verified" src={verified}/>
                  </div>
                  <div class="text-md font-secondary text-left mt-1 tweet-count">{availableCount} tweets available for mint</div>
                </div>
                <div class="borger xl:invisible visible text-xl" onClick={() => {setSideMenu(true)}}>
                  <i class="text-white fa-solid fa-bars"></i>
                </div>
              </div>
            </div>
            <div className='switch-content flex flex-row'>
                <div onClick={() => filterData(true)}  className={"switch-button border-l" + (availableActive ? " active-switch-btn" : "")}>
                  <div>Available</div>
                </div>
                <div onClick={() => filterData(false)} className={"switch-button border-r" + (!availableActive ? " active-switch-btn" : "")}>
                  <div>Minted</div>
                </div>
            </div>
        </div>
        <div class="tweet-section pt-20">
          {filteredData.length > 0 && (
            filteredData.map((tweet, index) => 
              <TweetCard key={index} tweet={tweet} web3User={web3User} contract={contract}></TweetCard>
              )
            )}
            {loading && (
               Array(5).fill(exampleTweet).map((tweet, index) => 
                <TweetCard key={index} tweet={tweet} web3User={web3User} contract={contract}></TweetCard>
                )
            )}
        </div>
      </div>
    )
  }