import React, {useEffect, useState} from 'react'
import avatar from '../avatar.jpg';
import verified from '../verified.png';
import {ethers} from 'ethers'
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import { getDatabase, set, ref } from 'firebase/database';
import { app } from '../index';

export default function TweetCard({tweet, web3User, contract, key}) {

    const [dateString, setDateString] = useState('');
    const [networkErr, setNetworkErr] = useState(false);
    const [mintErr, setMintErr] = useState('');
    const [mintSuccess, setMintSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [txHash, setTxHash] = useState('');
    const [receipt, setReceipt] = useState({});


    const action = (
      <a href={`https://etherscan.io/tx/${txHash}`}  target="_blank">
        <Button color="secondary" size="small">
          View transaction
        </Button>
      </a>
    );

    function writeNFTdata(tokenId = tweet.id) {
      const db = getDatabase(app);
      set(ref(db, 'nft-data/' + (tokenId - 1)), {
        ...tweet,
        owner: web3User
      }).then(res => {
        setMintSuccess(true);
        setLoading(false);
      });
    }

    useEffect(() => {
      const dateObj = new Date(tweet.date);
      if(tweet.date) {
        setDateString(`${dateObj.toDateString().split(' ')[1]} ${dateObj.getUTCDate()}, ${dateObj.toDateString().split(' ')[3]}`);
      }
    }, []);

    const handleMint = async (tokenId) => {
      setNetworkErr(false);
      if(web3User) {
        setLoading(true);
        let fee = 0;
        let signer = new ethers.providers.Web3Provider(window.ethereum).getSigner();
        try {
          fee = ethers.utils.parseEther('0.5');
        } catch(err) {
            setNetworkErr(true);
            return;
        }
        contract.connect(signer).mintToken(tweet.id, {value: fee}).then(function(txn) {
            txn.wait().then(async function(receipt) {
              setReceipt(receipt);
              setLoading(false);
              if (receipt.status == 1) {
                  setTxHash(receipt.transactionHash);
                  writeNFTdata(tweet.id);
              }
              if (receipt.status == 0) {
                setMintErr("Error minting");
                setLoading(false);
                return;
              }
          }).catch(function(err) {
              setLoading(false);
              setNetworkErr(true);
              return;
          });
        }, fail => {
          setLoading(false);
          if(fail.error && fail.error.message) {
            setMintErr(fail.error.message);
          }
          
        })  
      }
    }

    return (
      <div className="tweet-body flex flex-row" key={key}>
        <div className="tweet-avatar mr-3"><img src={avatar}/></div>
        <div className='tweet-content mt-3'>
          <div className="flex flex-row gap-1 username">
            <p className="text-left text-white username pb-3"><a href="https://x.com/OhYouBlockhead" class="link-underline" target="_blank">Blockhead</a></p>
            <img className="mx-1" src={verified}/>
            <div className='font-secondary tweet-meta'>
              <span className="userhandle-lg">@OhYouBlockhead</span>
              <span className="userhandle-sm">@OhY...</span>
              {dateString && (<span><span className='font-bold px-1'>·</span>{dateString}</span>)}
            </div>
          </div>
          <p className="text-left tweet-text">{tweet.body}</p>
          { tweet.owner && (<p className="py-2 pt-3 text-sm text-left font-secondary owner-lg">owner: {tweet.owner}</p>)}
          
          { tweet.owner && (<p className="py-2 pt-3 text-sm text-left font-secondary owner-sm">owner: {`${tweet.owner.slice(0, 6)}...${tweet.owner.slice(38, 42)}`}</p>)}
          <div className="w-100 justify-end flex flex-row">
          {
            !tweet.owner && (loading ? <div className="w-10 pr-16"><span class="loader"></span></div> : (<div class="flex flex-row items-center justify-end mt-3 gap-4"><a class="link-underline font-secondary" target="_blank" href={tweet.url}>see on X</a><div onClick={() => handleMint(tweet.id)} disabled={!web3User} className={(web3User ? '' : 'btn-disabled ') + "btn-main btn-tweet py-2 px-4 cursor-pointer rounded-full"}>Mint for 0.5Ξ</div></div>))
          }
          </div>
        </div>
        <Snackbar open={!!mintErr} message={mintErr} autoHideDuration={7000} anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
        }} onClose={() => setMintErr('')}>
        </Snackbar> 
        <Snackbar open={mintSuccess} message={`Tweet with ID ${tweet.id} minted successfully!`} autoHideDuration={7000} anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
        }} action={action} onClose={() => {setMintSuccess(false); setTxHash('')}}>
        </Snackbar> 
      </div>
    )
  }