import './App.css';
import {useState, useEffect} from 'react'
import Header from './components/Header';
import Feed from './components/Feed';
import  { providers } from 'web3modal';
import {AuthContext} from './contexts/AuthContext';
import {ALCHEMY_KEY, NETWORK, CONTRACT_ADDRESS} from './web3config'
import ABI from './contract/ABI.json'
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";

import {ethers} from 'ethers'


const providerOptions = {
  // coinbasewallet: {
  //   package: CoinbaseWalletSDK,
  //   options: {
  //     appName: "Blockhead.press",
  //     infuraId: "cf702e9445fd4395b9108b3ab032cb10"
  //   }
  // }, 
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: "cf702e9445fd4395b9108b3ab032cb10",
    }
  }
}

function App() {

  const [web3User, setWeb3User] = useState(null);
  const [contract, setContract] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [sideMenu, setSideMenu] = useState(false);

  useEffect(() => {
    if(localStorage.getItem('acc')) {
      connectWallet();
    }
   
    if(window.ethereum) {
      window.ethereum.on('accountsChanged', function (accounts) {
        setWeb3User(accounts[0]);
      })
    }
    
    let alchemyProvider = new ethers.providers.AlchemyProvider(NETWORK, ALCHEMY_KEY);
    let goodGriefsContract = new ethers.Contract(CONTRACT_ADDRESS, ABI, alchemyProvider);
    setContract(goodGriefsContract);
    window.addEventListener("resize", handleResize)
  },[])

  async function connectWallet() {

    if (!window.ethereum && !isMobile) {
      providerOptions['custom-metamask'] = {
        display: {
          logo: providers.METAMASK.logo,
          name: 'Install MetaMask',
          description: 'Connect using browser wallet'
        },
        package: {},
        connector: async () => {
          window.open('https://metamask.io')
          throw new Error('MetaMask not installed');
        }
      }
    }
    try {
      let web3Modal = new Web3Modal({
        cacheProvider: true,
        providerOptions
      })
      const web3ModalInstance = await web3Modal.connect();
      const web3ModalProvider = new ethers.providers.Web3Provider(web3ModalInstance);
   
      if(web3ModalProvider) {
        setWeb3User(web3ModalProvider.provider.selectedAddress);
      }

    } catch(error) {
      console.error(error);
    }
  }

  const handleResize = () => {
    if (window.innerWidth < 768) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }

  return (
    
      <div className="App">
        <AuthContext.Provider value={{setWeb3User, web3User, contract, sideMenu, setSideMenu}}>
          <Header connectWallet={connectWallet}/>
          <Feed/>
        </AuthContext.Provider>
      </div>
  );
}

export default App;
